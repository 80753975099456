import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';

import GlobalStyle from './styles/global';
import AppProvider from './hooks';

import { store } from './store';
import Routes from './routes';

const App: React.FC = () => {
  return (
    <Provider {...store}>
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>

        <GlobalStyle />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
