import React, { useRef, useCallback } from 'react';
import { MdBuild, MdDomain, MdPhoneAndroid } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { FiLock, FiMail, FiMapPin, FiPhone, FiUser } from 'react-icons/fi';
import api from '../../services/api';

import { useToast } from '../../hooks/Toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { Container, Content, AnimationContainer } from './styles';

interface SignUpFormData {
  name: string;
  last_name: string;
  company: string;
  email: string;
  password: string;
  cnpj: string;
  insc_estadual: string;
  insc_municipal: string;
  tel: string;
  cel: string;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
  rua: string;
  complemento: string;
  num_estabelecimento: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  // const formData = new FormData();

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          last_name: Yup.string().max(32).required('Ultimo nome obrigatório'),
          company: Yup.string().max(32).required('Nome da empresa obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email váliado'),
          password: Yup.string()
            .min(6, 'mínimo 6 digitos')
            .required('mínimo 6 digitos'),
          cnpj: Yup.string()
            .min(14, 'CNPJ obrigatório')
            .max(14)
            .required('CNPJ obrigatório'),
          insc_estadual: Yup.string().min(9, 'Mínimo 9 caracteres').max(9),
          insc_municipal: Yup.string().min(9, 'Mínimo 9 caracteres').max(9),
          tel: Yup.string().max(32).required('Telefone obrigatório'),
          cel: Yup.string().max(32).required('Celular obrigatório'),
          cep: Yup.string().min(8).max(8).required('Cep obrigatório'),
          estado: Yup.string().max(2).required('Indicar estado obrigatório'),
          cidade: Yup.string().required('Indicar cidade obrigatório'),
          bairro: Yup.string().max(32).required('Bairro obrigatório'),
          rua: Yup.string().max(32).required('Rua obrigatório'),
          complemento: Yup.string().max(50),
          num_estabelecimento: Yup.string().required(
            'Número do estabelecimento obrigatório',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users', data);

        history.push('/dashboard');

        addToast({
          type: 'success',
          title: 'Cliente cadastrado com sucesso!',
          description: 'Você já pode visualiza-lo no painel.',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro ao cadastrar Cliente',
          description: 'cheque os dados e tente novamente.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Header />
      <Container>
        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Adicionar Cliente</h1>
              <Input name="name" icon={FiUser} placeholder="Nome do cliente" />
              <Input
                name="last_name"
                icon={FiUser}
                placeholder="Último sobrenome"
              />
              <Input name="company" icon={MdDomain} placeholder="Empresa" />
              <Input name="email" icon={FiMail} placeholder="Email" />
              <Input name="password" icon={FiLock} placeholder="Senha" />
              <Input name="tel" icon={FiPhone} placeholder="Telefone" />
              <Input name="cel" icon={MdPhoneAndroid} placeholder="Celular" />
              <Input name="cnpj" icon={MdBuild} placeholder="CNPJ" />
              <Input
                name="insc_estadual"
                icon={MdDomain}
                placeholder="Inscrição Estadual"
              />
              <Input
                name="insc_municipal"
                icon={MdDomain}
                placeholder="Inscrição Municipal"
              />
              <Input name="cep" icon={FiMapPin} placeholder="CEP" />
              <Input name="estado" icon={FiMapPin} placeholder="Estado" />
              <Input name="cidade" icon={FiMapPin} placeholder="Cidade" />
              <Input name="bairro" icon={FiMapPin} placeholder="Bairro" />
              <Input name="rua" icon={FiMapPin} placeholder="Rua" />
              <Input
                name="complemento"
                icon={FiMapPin}
                placeholder="Complemento"
              />
              <Input
                name="num_estabelecimento"
                icon={FiMapPin}
                placeholder="Nº do estabalecimento"
              />

              <Button type="submit">Adicionar</Button>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default SignUp;
