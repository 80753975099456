import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import ChangePassword from '../pages/Password';
import Report from '../pages/Report';
import ProductCreate from '../pages/ProductCreate';
import License from '../pages/License';
import BoletoCreate from '../pages/Boleto';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import DeleteLicenses from '../pages/DeleteLicenses';
import UpdateUser from '../pages/UpdateUser';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} isPrivate />

    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/password" component={ChangePassword} isPrivate />
    <Route path="/reports" component={Report} isPrivate />
    <Route path="/Product-create" component={ProductCreate} isPrivate />
    <Route path="/license" component={License} isPrivate />
    <Route path="/boleto" component={BoletoCreate} isPrivate />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/delete-license" component={DeleteLicenses} isPrivate />
    <Route path="/update-user" component={UpdateUser} isPrivate />
  </Switch>
);

export default Routes;
