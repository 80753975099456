import styled from 'styled-components';

export const Container = styled.footer`
  img {
    size: 10px;
  }
  footer {
    background-color: #28262e;
    width: 100%;
    /* left: 0; */

    display: flex;
    justify-content: flex-end;

    align-items: center;
    /* margin: 4px 0; */
    margin-top: 80px;

    /* position: inherit; */
    bottom: 0;
    img {
      size: 10px;
    }
    div {
      text-align: right;
      margin-right: 10px;
      strong {
        display: block;
        color: #fff;
      }
      span {
        font-size: 12px;
        color: #999;
      }
    }
  }
`;
