import React from 'react';
import { observer } from 'mobx-react-lite';
import { MdCall } from 'react-icons/md';
import { FiLinkedin } from 'react-icons/fi';
import { Container } from './styles';

import logo from '../../assets/logo_branco.png';

const Footer = observer(() => {
  return (
    <Container>
      <footer>
        <MdCall size={36} color="#FFF" />
        <div>
          <strong>(31) 32815522</strong>
        </div>

        <a href="https://www.linkedin.com/company/microhard-inform%C3%A1tica/">
          <FiLinkedin size={36} color="#FFF" />{' '}
        </a>
        <img src={logo} alt="microhard" height={80} />
      </footer>
    </Container>
  );
});

export default Footer;
