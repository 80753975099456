import { observable, computed, action } from 'mobx';
import { formatPrice } from '../utils/format';
import api from '../services/api';

interface IProduct {
  id: number;
  title: string;
  valor_unitario: number;
  image: string;
  amount: number;
  formatedPrice?: string;
  subtotal?: string;
}

// Veja se pode remover isto
interface IStock {
  id: number;
  amount: number;
}

export default class CartStore {
  @observable cart: IProduct[] = [];

  @observable products: IProduct[] = [];

  public addProduct = async (product: IProduct) => {
    const find = this.cart.find((i) => i.id === product.id);

    if (find) {
      await this.increment(find);
    } else {
      this.cart.push({
        ...product,
        amount: 1,
        formatedPrice: formatPrice(product.valor_unitario),
        subtotal: formatPrice(product.valor_unitario * 1),
      });
    }
  };

  public fetchProducts = async () => {
    const { data } = await api.get<IProduct[]>('products');
    this.products = data.map((i) => ({
      ...i,
      formatedPrice: formatPrice(i.valor_unitario),
    }));
  };

  public removeFromCart = (id: number) => {
    this.cart = this.cart.filter((i) => i.id !== id);
  };

  @action
  public increment = async (product: IProduct) => {
    // Veja se pode remover isto
    // const { data } = await api.get<IStock>(`stock/${product.id}`);

    product.amount += 1;
    product.formatedPrice = formatPrice(product.valor_unitario);
    product.subtotal = formatPrice(product.valor_unitario * product.amount);
  };

  public decrement = (product: IProduct) => {
    product.amount = product.amount > 1 ? (product.amount -= 1) : 1;
    product.subtotal = formatPrice(product.valor_unitario * product.amount);
  };

  @computed
  get total() {
    return formatPrice(
      this.cart.reduce((total, product) => {
        return total + product.amount * product.valor_unitario;
      }, 0),
    );
  }

  @computed
  get amount() {
    return this.cart.reduce((amount, product) => {
      amount[product.id] = product.amount;
      return amount;
    }, {} as any);
  }
}
