import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { MdEdit, MdGetApp } from 'react-icons/md';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { Container, ProductTable, ProductDetails } from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { formatPrice } from '../../utils/format';

import BoletoSituation from '../BoletoSituation';
import SearchLicenses from '../../components/SearchLicenses';

const Report: React.FC = observer(() => {
  const [boletos, setBoletos] = useState([]);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getBoletos = async () => {
      const { data } = await api.get('/boletos', {
        headers: {
          page: 1,
        },
      });

      setBoletos(
        data.boletos.map((boleto: any) => {
          return {
            ...boleto,
          };
        }),
      );
    };

    const getUsers = async () => {
      const { data } = await api.get('/users', {
        headers: {
          page: 1,
        },
      });

      setUsers(
        data.users.map((user: any) => {
          return {
            ...user,
          };
        }),
      );
    };

    const getProducts = async () => {
      const { data } = await api.get('/products', {
        headers: {
          page: 1,
        },
      });

      setProducts(
        data.products.map((product: any) => {
          return {
            ...product,
            formatPrice: formatPrice(product.valor_unitario),
          };
        }),
      );
    };

    getProducts();
    getUsers();
    getBoletos();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <ProductDetails>
          <Tabs>
            <TabList>
              <Tab>
                <h1>CLIENTES</h1>
              </Tab>
              <Tab>
                <h1>LICENÇAS</h1>
              </Tab>
              <Tab>
                <h1>BOLETOS</h1>
              </Tab>
              <Tab>
                <h1>PRODUTOS</h1>
              </Tab>
            </TabList>

            <TabPanel>
              <ProductTable>
                <thead>
                  <tr>
                    <th>EMPRESA</th>
                    <th>EMAIL</th>
                    <th>CNPJ</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user: any) => {
                    const url = `/update-user/?id=${user.id}`;
                    return (
                      <tr key={user.id}>
                        <td>
                          <span>{user.company}</span>
                        </td>
                        <td>
                          <span>{user.email}</span>
                        </td>
                        <td>
                          <span>{user.cnpj}</span>
                        </td>
                        <td>
                          <div>
                            <span>{user.id}</span>
                            <div>
                              <Link to={url}>
                                <MdEdit />
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td />
                      </tr>
                    );
                  })}
                </tbody>
              </ProductTable>
            </TabPanel>

            <TabPanel>
              <SearchLicenses />

              {/* <ProductTable>
                <thead>
                  <tr>
                    <th>PRODUTO</th>
                    <th>CHAVE DA LICENÇA</th>
                    <th>ID DA EMPRESA</th>
                  </tr>
                </thead>
                <tbody>
                  {licenses.map((license: any) => {
                    return (
                      <tr key={license.id}>
                        <td>
                          <div>
                            <span>{license.product_name}</span>
                          </div>
                        </td>
                        <td>
                          <span>{license.title}</span>
                        </td>
                        <td>
                          <span>{license.user_id}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ProductTable> */}
            </TabPanel>

            <TabPanel>
              <ProductTable>
                <thead>
                  <tr>
                    <th>BOLETO</th>
                    <th>ID DO CLIENTE</th>
                    <th>ID DO BOLETO</th>
                    <th>SITUAÇÃO</th>
                  </tr>
                </thead>
                <tbody>
                  {boletos.map((boleto: any) => {
                    let situation = 'ABERTO';

                    if (boleto.paid === true) {
                      situation = 'PAGO';
                    }

                    return (
                      <tr key={boleto.id}>
                        <td>
                          <div>
                            <MdGetApp />
                            <a href={boleto.file_url}>{boleto.img}</a>
                          </div>
                        </td>

                        <td>
                          <span>{boleto.user_id}</span>
                        </td>
                        <td>
                          <span>{boleto.id}</span>
                        </td>
                        <td>
                          <span> {situation}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ProductTable>
              <BoletoSituation />
            </TabPanel>
            <TabPanel>
              <ProductTable>
                <thead>
                  <tr>
                    <th>NOME DO PRODUTO</th>
                    <th>PART NUMBER</th>
                    <th>VALOR UNITÁRIO</th>
                    <th>ID DO PRODUTO</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product: any) => {
                    return (
                      <tr key={product.id}>
                        <td>
                          <span>{product.name}</span>
                        </td>
                        <td>
                          <span>{product.pn}</span>
                        </td>
                        <td>
                          <span>{product.formatPrice}</span>
                        </td>
                        <td>
                          <span>{product.id}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ProductTable>
            </TabPanel>
          </Tabs>
        </ProductDetails>
      </Container>
      <Footer />
    </>
  );
});

export default Report;
