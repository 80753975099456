/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useCallback, ChangeEvent } from 'react';
import {
  MdAttachMoney,
  MdDescription,
  MdDateRange,
  MdBuild,
  MdReceipt,
  MdDomain,
  MdFileUpload,
} from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';

import { useToast } from '../../hooks/Toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { Container, Content, AnimationContainer } from './styles';

interface ProductCreateFormData {
  name: string;
  pn: string;
  descricao: string;
  range: string;
  periodo: string;
  tipo: string;
  fabricante: string;
  valor_unitario: number;
}

const ProductCreate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formData = new FormData();

  const { addToast } = useToast();
  const history = useHistory();

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) formData.append('img', e.target.files[0]);
    },
    [formData],
  );

  const handleSubmit = useCallback(
    async (data: ProductCreateFormData | any) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('E-mail obrigatório'),
          pn: Yup.string().required('Part number obrigatório'),
          descricao: Yup.string().required('obrigatório'),
          range: Yup.string().required('Range obrigatório'),
          periodo: Yup.string().required('Periodo obrigatório'),
          tipo: Yup.string().required('Tipo obrigatório'),
          fabricante: Yup.string().required('Fabricante obrigatório'),
          valor_unitario: Yup.number().required('Valor Unitário obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        history.push('/dashboard');

        for (const key in data) formData.append(key, data[key]);

        await api.post('/products', formData);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro ao cadastrar produto',
          description: 'cheque os dados e tente novamente.',
        });
      }
    },
    [addToast, history, formData],
  );

  return (
    <>
      <Header />
      <Container>
        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Adicionar Produto</h1>
              <Input name="name" icon={MdBuild} placeholder="Nome do produto" />
              <Input name="pn" icon={MdReceipt} placeholder="Part Number" />
              <Input
                name="descricao"
                icon={MdDescription}
                placeholder="Descrição"
              />
              <Input name="range" icon={MdBuild} placeholder="Range" />
              <Input name="periodo" icon={MdDateRange} placeholder="Período" />
              <Input name="tipo" icon={MdBuild} placeholder="Tipo" />

              <Input
                name="fabricante"
                icon={MdDomain}
                placeholder="Fabricante"
              />
              <Input
                name="valor_unitario"
                icon={MdAttachMoney}
                placeholder="Valor Unitário"
              />
              <Input
                name="img"
                type="file"
                icon={MdFileUpload}
                onChange={handleAvatarChange}
                placeholder="Imagem"
              />
              <Button type="submit">Adicionar</Button>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default ProductCreate;
