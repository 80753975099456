import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { CSVReader } from 'react-papaparse';
import api from '../../services/api';

import { useToast } from '../../hooks/Toast';

import Button from '../../components/Button';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { Container, Content, AnimationContainer } from './styles';

const DeleteLicenses: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  let licenses: any;

  const handleOnDrop = async (data: any) => {
    const licensesMap = data.map((data: any) => {
      return data.data;
    });

    const licensesData = { licenses: licensesMap };

    licenses = licensesData;

    return licenses;
  };

  const handleOnError = (
    err: any,
    file: any,
    inputElem: any,
    reason: any,
  ) => {};

  const handleOnRemoveFile = (data: any) => {};

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        console.log(licenses);

        await api.post('/licenses/delete', licenses);
        addToast({
          type: 'success',
          title: 'Licenças canceladas com sucesso!',
          description: '',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao remover Licenças',
          description: 'cheque os dados e tente novamente.',
        });
      }
    },
    [addToast, licenses],
  );

  return (
    <>
      <Header />
      <Container>
        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Remover Licenças</h1>
              <CSVReader
                onDrop={handleOnDrop}
                onError={handleOnError}
                noClick
                key="reader"
                addRemoveButton
                onRemoveFile={handleOnRemoveFile}
                config={{
                  delimiter: '', // auto-detect
                  newline: '', // auto-detect
                  quoteChar: '"',
                  escapeChar: '"',
                  header: true,
                  transformHeader: undefined,
                  dynamicTyping: false,
                  preview: 0,
                  encoding: '',
                  worker: false,
                  comments: false,
                  step: undefined,
                  complete: undefined,
                  error: undefined,
                  download: false,
                  downloadRequestHeaders: undefined,
                  skipEmptyLines: true,
                  chunk: undefined,
                  fastMode: undefined,
                  beforeFirstChunk: undefined,
                  withCredentials: undefined,
                  transform: undefined,
                }}
                style={{
                  fileSizeInfo: {
                    color: '#eee',
                    backgroundColor: '#312e38',
                    borderRadius: 3,
                    lineHeight: 1,
                    marginBottom: '0.5em',
                    padding: '0 0.4em',
                  },
                  fileNameInfo: {
                    color: '#232129',
                    backgroundColor: '#eee',
                    borderRadius: 3,
                    fontSize: 14,
                    lineHeight: 1,
                    padding: '0 0.4em',
                  },
                }}
              />
              <Button type="submit">Remover</Button>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default DeleteLicenses;
