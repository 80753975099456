/* eslint-disable array-callback-return */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  MdAccountCircle,
  MdEmail,
  MdGpsFixed,
  MdPhone,
  MdPhoneAndroid,
} from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';

import { useToast } from '../../hooks/Toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { Container, Content, AnimationContainer } from './styles';

interface UpdateUserFormData {
  name: string;
  email: string;
  password: string;
  id: string;
  company: string;
  cnpj: string;
  insc_estadual: string;
  insc_municipal: string;
  tel: string;
  cel: string;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
  rua: string;
  complemento: string;
  num_estabelecimento: string;
}

const UpdateUser: React.FC = () => {
  const [users, setUser] = useState([]);
  let name = '';
  let email = '';
  let tel = '';
  let cel = '';
  let company = '';
  let cnpj = '';
  let insc_estadual = '';
  let insc_municipal = '';
  let cep = '';
  let estado = '';
  let bairro = '';
  let cidade = '';
  let rua = '';
  let complemento = '';
  let num_estabelecimento = '';
  const params = new URLSearchParams(document.location.search.substring(1));
  const id = params.get('id');

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      const { data } = await api.get(`/users/${id} `, {
        headers: {
          page: 1,
        },
      });

      setUser(
        data.map((user: UpdateUserFormData) => {
          return {
            ...user,
          };
        }),
      );
    };

    getUser();
  }, [id]);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string(),
          email: Yup.string().email('email inválido'),
          tel: Yup.string(),
          cel: Yup.string(),
          estado: Yup.string(),
          cidade: Yup.string(),
          bairro: Yup.string(),
          rua: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          user_id: id,
          name: data.name,
          email: data.email,
          tel: data.tel,
          cel: data.cel,
          estado: data.estado,
          cidade: data.cidade,
          bairro: data.bairro,
          rua: data.rua,
        };

        history.push('/dashboard');
        await api.post('/users/edit', formData);

        addToast({
          type: 'success',
          title: 'Dados do cliente alterados com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro ao alterar dados do cliente',
          description: 'cheque os dados e tente novamente.',
        });
      }
    },
    [addToast, history, id],
  );

  return (
    <>
      <Header />
      <Container>
        <Content>
          <AnimationContainer>
            {users.map((user: UpdateUserFormData) => {
              name = user.name;
              email = user.email;
              tel = user.tel;
              cel = user.cel;
              company = user.company;
              cnpj = user.cnpj;
              insc_estadual = user.insc_estadual;
              insc_municipal = user.insc_municipal;
              cep = user.cep;
              estado = user.estado;
              cidade = user.cidade;
              bairro = user.bairro;
              rua = user.rua;
              complemento = user.complemento;
              num_estabelecimento = user.num_estabelecimento;
            })}
            <Form
              ref={formRef}
              initialData={{
                name,
                email,
                tel,
                cel,
                company,
                cnpj,
                insc_estadual,
                insc_municipal,
                cep,
                estado,
                bairro,
                cidade,
                rua,
                complemento,
                num_estabelecimento,
              }}
              onSubmit={handleSubmit}
            >
              <h1>Alterar dados do cliente</h1>
              <Input name="name" icon={MdAccountCircle} placeholder="Nome " />
              <Input name="email" icon={MdEmail} placeholder="email" />
              <Input name="tel" icon={MdPhone} placeholder="tel" />
              <Input name="cel" icon={MdPhoneAndroid} placeholder="cel" />
              <Input name="estado" icon={MdGpsFixed} placeholder="estado" />
              <Input name="cidade" icon={MdGpsFixed} placeholder="cidade" />
              <Input name="bairro" icon={MdGpsFixed} placeholder="bairro" />
              <Input name="rua" icon={MdGpsFixed} placeholder="rua" />
              <Button type="submit">Alterar</Button>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default UpdateUser;
