import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  padding: 30px;
  background: #f4ede8;
  border-radius: 4px;
  margin-left: 5%;
  margin-right: 5%;
`;

export const ProductTable = styled.table`
  width: 100%;
  color: #333;

  thead th {
    color: #999;
    text-align: left;
    padding: 12px;
  }
  tbody td {
    padding: 12px;
    border-bottom: 1px solid #eee;
  }
  img {
    height: 100px;
  }
  strong {
    color: #333;
    display: block;
  }
  span {
    display: block;
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  div {
    display: flex;
    align-items: center;
    input {
      border: 1px solid #ddd;
      padding: 4px;
      border-radius: 4px;
      color: #666;
      width: 50px;
    }
  }
  footer {
    button {
      background: #7159c1;
      color: #f4ede8;
      border: 0;
      border-radius: 4px;
      overflow: hidden;
      margin-top: auto;
      display: flex;
      align-items: center;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#7159c1')};
      }
    }
  }
`;

export const ProductDetails = styled.div`
  margin-top: 70px;
  color: #333;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 20px;
    justify-content: space-around;

    li {
      display: block;
      &:hover {
        color: ${darken(0.03, '#999')};
      }
    }
    h2 {
      margin-top: 30px;
    }
  }
`;
