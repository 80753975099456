import React, { useRef } from 'react';
import { MdBuild } from 'react-icons/md';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router';
import api from '../../services/api';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content } from './styles';

const BoletoSituation: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    await api.patch('/boletos', data);

    history.push('/');
  };

  return (
    <>
      <Container>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Confirmar Pagamento do boleto</h1>
            <Input name="id" icon={MdBuild} placeholder="Id Do boleto" />
            <Button type="submit">Confirmar Pagamento</Button>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default BoletoSituation;
