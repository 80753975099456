import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import {
  MdExitToApp,
  MdAccountCircle,
  MdAssessment,
  MdPersonAdd,
  MdAddBox,
  MdReceipt,
  MdMonetizationOn,
  MdWarning,
} from 'react-icons/md';
import { Container, SignOut, Password, Reports, License } from './styles';

import { useAuth } from '../../hooks/Auth';
import logo from '../../assets/micrologo.svg';

const Header = observer(() => {
  const { signOut } = useAuth();

  return (
    <Container>
      <Link to="/">
        <img src={logo} alt="microhard" />
      </Link>

      <Password to="/password">
        <div>
          <strong>Alterar dados</strong>
        </div>
        <MdAccountCircle size={36} color="#FFF" />
      </Password>
      <Reports to="/reports">
        <div>
          <strong>Painel</strong>
        </div>
        <MdAssessment size={36} color="#FFF" />{' '}
      </Reports>

      <Password to="/signup">
        <div>
          <strong>Adicionar Cliente</strong>
        </div>
        <MdPersonAdd size={36} color="#FFF" />
      </Password>

      <License to="/license">
        <div>
          <strong>Importar Licenças</strong>
        </div>
        <MdReceipt size={36} color="#FFF" />
      </License>

      <License to="/delete-license">
        <div>
          <strong>Remover Licenças</strong>
        </div>
        <MdWarning size={36} color="#FFF" />
      </License>

      <Password to="/product-create">
        <div>
          <strong>Adicionar Produto</strong>
        </div>
        <MdAddBox size={36} color="#FFF" />
      </Password>

      <Password to="/boleto">
        <div>
          <strong>Adicionar Boleto</strong>
        </div>
        <MdMonetizationOn size={36} color="#FFF" />
      </Password>

      <SignOut to="/" onClick={signOut}>
        <div>
          <strong>Sair</strong>
        </div>
        <MdExitToApp size={36} color="#FFF" />{' '}
      </SignOut>
    </Container>
  );
});

export default Header;
