import React, { useRef } from 'react';
import { MdBuild } from 'react-icons/md';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import api from '../../services/api';

import Input from '../Input';
import Button from '../Button';

import { Container, Content } from './styles';

const SearchLicenses: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  let csv: any = [];

  const handleSubmit: SubmitHandler<FormData> = async (data: any) => {
    const { id } = data;
    const ids = { user_id: id };

    const user_licenses = await api.post('/licenses/user', ids);

    csv = user_licenses.data;

    // eslint-disable-next-line prefer-destructuring

    let csvContent = `data:array/csv;charset=utf-8,`;

    csvContent += 'key ; product_name';
    csvContent += '\n';

    csv[0].forEach((element: any) => {
      csvContent += `${element.key}`;
      csvContent += ';';

      csvContent += `${element.product_name}`;
      csvContent += ';';

      csvContent += '\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'my_data.csv');
    document.body.appendChild(link);

    link.click();
  };

  return (
    <>
      <Container>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>baixar extrato de licenças ativas por empresa</h1>
            <Input name="id" icon={MdBuild} placeholder="Id da empresa" />

            <Button type="submit">baixar</Button>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default SearchLicenses;
