import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
`;

export const Content = styled.div`
  margin-top: 0;
  display: flex;
  align-items: row;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
`;
