/* eslint-disable no-restricted-syntax */
import React, { useRef, useCallback, ChangeEvent } from 'react';
import { MdBuild, MdFileUpload } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';

import { useToast } from '../../hooks/Toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { Container, Content, AnimationContainer } from './styles';

const BoletoCreate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formData = new FormData();

  const { addToast } = useToast();
  const history = useHistory();

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) formData.append('img', e.target.files[0]);
    },
    [formData],
  );

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          user_id: Yup.string().required('ID do cliente obrigatórios'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line guard-for-in
        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line guard-for-in
        for (const key in data) formData.append(key, data[key]);

        await api.post('/boletos', formData);

        history.push('/reports');

        addToast({
          type: 'success',
          title: 'Boleto adicionado com sucesso!',
          description: 'você ja pode visualiza-lo no painel.',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro ao cadastrar Boleto',
          description: 'cheque os dados e tente novamente.',
        });
      }
    },
    [addToast, history, formData],
  );

  return (
    <>
      <Header />
      <Container>
        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Adicionar Boleto</h1>
              <Input
                name="user_id"
                icon={MdBuild}
                placeholder="ID do cliente"
              />
              <Input
                name="img"
                type="file"
                icon={MdFileUpload}
                onChange={handleAvatarChange}
                placeholder="Imagem"
              />
              <Button type="submit">Adicionar</Button>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default BoletoCreate;
